// https://hackernoon.com/how-to-build-a-qr-code-generator-in-react
import QrCode from "./components/QrCode";

function App() {
  return (
    <div className="App">
      <div className="flex h-screen w-screen overflow-y-scroll items-center justify-center bg-gray-50">
        <QrCode />
      </div>
    </div>
  );
}

export default App;
