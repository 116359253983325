import { useState, useRef } from "react";
import { QRCodeCanvas } from "qrcode.react";

const QrCode = () => {
  const [url, setUrl] = useState("");
  const qrRef = useRef();
  const downloadQRCode = (e) => {
    e.preventDefault();
    let canvas = qrRef.current.querySelector("canvas");
    let image = canvas.toDataURL("image/png");
    let anchor = document.createElement("a");
    anchor.href = image;
    anchor.download = `qr-code.png`;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    setUrl("");
  };
  const qrCodeEncoder = (e) => {
    setUrl(e.target.value);
  };

  const qrcode = (
    <QRCodeCanvas
      id="qrCode"
      value={url}
      size={300}
      bgColor={"#ffffff"}
      level={"H"}
    />
  );
  return (
    <div className="qrcode__container flex flex-col gap-y-10 bg-white p-10 rounded-xl shadow-xl">
      <h2 className="text-2xl font-semibold">Create your own QR Code</h2>
      <div ref={qrRef}>{qrcode}</div>
      <div className="input__group">
        <form onSubmit={downloadQRCode} className=" flex flex-col gap-10">
          <div className="flex flex-col gap-4">
            <label>Enter URL</label>
            <input
              type="text"
              value={url}
              onChange={qrCodeEncoder}
              placeholder="https://hackernoon.com"
              className="border-b-2 p-2"
            />
          </div>
          <button
            type="submit"
            disabled={!url}
            className="self-end bg-green-500 px-4 py-2 rounded-md shadow-xl disabled:bg-gray-200"
          >
            Download QR code
          </button>
        </form>
      </div>
    </div>
  );
};

export default QrCode;
